<!--  -->
<template>
  <div class="jdal">
    <div class="ywlybanner">
      <img src="../assets/jdal.jpg" alt="" />
    </div>
    <div class="jdalcen">
      <!-- <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="推荐" name="1"></el-tab-pane>
        <el-tab-pane label="企业网站" name="2"></el-tab-pane>
        <el-tab-pane label="小程序" name="3"></el-tab-pane>
        <el-tab-pane label="APP" name="4"></el-tab-pane>
      </el-tabs> -->
    </div>
    <div class="jdalContent">
      <div class="mydiv" v-for="(item, index) in hzdwcontent" :key="index">
        <img
          :src="
            'http://tj-dasheng.com/api/sysFileInfo/public/preview?fileId=' +
            item.fileId
          "
          alt=""
        />
        <div class="jdalmytit">{{ item.title }}</div>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      activeName: "1",
      hzdwcontent: [],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    handleClick(tab, event) {
      console.log(this.activeName);
      if (this.activeName == '1') {
        this.aaa();
      } else if (this.activeName == '2') {
        this.aaa(1);
      } else if (this.activeName == '3') {
        this.aaa(2);
      } else if (this.activeName == '4') {
        this.aaa(3);
      }
    },

    aaa(e) {
      this.$api
        .wxclassicCasepage({ type: e })
        .then((res) => {
          this.hzdwcontent = res.data.rows;
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.aaa();
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style>
.jdal .el-tabs__nav-wrap::after {
  background: #fff !important;
}
.jdal .el-tabs__item {
  font-size: 18px !important;
}
.jdal .el-tabs__item.is-active {
  font-weight: bold;
}
</style>
<style lang='less' scoped>
.ywlybanner {
  // height: 280px;
  img {
    width: 100%;
    // height: 100%;
  }
}
.jdalcen {
  width: 380px;
  margin: auto;
  padding: 57px 0;
}
.mydiv {
  width: 370px;
  margin: 0 30px 52px 0;
  box-shadow: 0 10px 26px 0 rgba(116, 116, 116, 0.16);
  img {
    width: 100%;
    height: 236px;
  }
  .jdalmytit {
    height: 86px;
    text-align: center;
    line-height: 86px;
    font-size: 20px;
    color: #333333;
    letter-spacing: 1.34px;
    text-align: center;
  }
}
.jdalContent {
  width: 1200px;
  margin: auto;
  display: flex;
  // justify-content: space-between;
  flex-wrap: wrap;
}
</style>